import React from "react"

import "./ToHiGanCard.css"

const ToHiGanCard = (props) => {

  return(
    <div className="ToHiGanCard">
      <div className="ToHiGanCardTitle">
        <p>TO HI GAN ZENDO – Át a túlsó partra</p>
      </div>
      <div className="ToHiGanCardPic">

      </div>
      <div className="ToHiGanCardText">
        <p>
          A <span>zen</span> a buddhizmus misztikus irányzata, több évszázados tapasztalatra támaszkodó spirituális iskola. Fő gyakorlata a <span>zazen</span> – szellemi összeszedettségben elmélyült csendes ülés. <span>A zen út</span> teljes egészében a megtapasztalás útja, ezért nem kötődik a vallási vagy filozófiai nézetekhez, így a buddhizmushoz sem, a vallásos embereket azonban saját vallásuk mélyebb megértéséhez vezetheti. Számos hagyomány, így a <span>kereszténység</span> is ismeri és alkalmazza a csendben ülés technikáját, mint spirituális gyakorlatot. A csendben önmagunkkal szembesülünk olyan mélységben, amely hétköznapi életben elképzelhetetlen. A zen út végső célja azonban nem az egyedül, elvonultan átélt megtapasztalás, vagy a mindennapoktól való elvonulás, hanem az, hogy a spirituális tapasztalatot átmentse a <span>hétköznapokba</span>, a munkánkba, a családba, a természethez és embertársainkhoz való viszonyunkba.
        </p>
        <p>
          <span>A TO HI GAN zendóban</span> a zazen technikáját (ülés, légzés, a gyakorlás időtartama) és a szertartási formákat <span>Willigis Jäger</span> ( Ko-Un Roshi), a Sanbo Kyodan iskola zen mesterének tanításai alapján gyakoroljuk. Willigis Jäger, bencés szerzetes, zen mester volt, a Benediktushof spirituális központ lelki vezetője, Németországban. A zendót <span>Marghescu Mária</span> alapította <span>és Subosits Tamással közösen</span> vezetik, mindketten Willigis Jäger tanítványai. A TO HI GAN zendót <span>mindenki</span> látogathatja, aki részt vett nálunk egy előzetes bevezető beszélgetésen vagy gyakorlaton, és csoportunkkal rendszeresen szeretne zen meditációt gyakorolni. A <span>zendo</span> ajtaja olyanok számára is nyitva áll, akik más spirituális gyakorlatot választottak, de elképzelhetőnek tartják, hogy emellett együtt gyakoroljanak velünk. Időpontot <span>bevezetőre</span> a megadott telefonszámon vagy e-mailben lehet kérni.
        </p>
      </div>
      <div className="ToHiGanCardTitle">
        <p>A Zen meditáció időpontjai</p>
      </div>
      <div className="ToHiGanCardFooter">
          <p><span>Szerda</span>, reggel 7:00-től 8:00-ig 2x25 perc, utána közös reggeli (fakultatív)</p>          
          <p>Augusztus hónapban a meditáció szünetel</p>
          <br />
          <p>Budapest, Váci utca 40. 1 emelet 7. (23-as csengő)</p>
          <br></br>
          <p>Marghescu Mária</p>
          <p>Mobil: +36 30 202 0842</p>
          <p>E-mail: marghescune.hunor.maria@gmail.com</p>
          <br></br>
          <p>Subosits Tamás</p>
          <p>Mobil: +36 70 318 3333</p>
          <p>E-mail: subositstamas@emisz.hu</p>
      </div>
    </div>
  )
}

export default ToHiGanCard
