import React from "react"
import Link from "gatsby-link"

import "./DesktopMenu.css"

class DesktopMenu extends React.Component {

  render() {
    return(
      <div id="DesktopMenu">
        <Link to="/Contact">
          <div className="MenuBtn"><p className="MenuBtnTitle">kapcsolat</p></div>
        </Link>
        <Link to="/Musing">
          <div className="MenuBtn"><p className="MenuBtnTitle">elmélkedések</p></div>
        </Link>
        <Link to="/Events">
          <div className="MenuBtn"><p className="MenuBtnTitle">események</p></div>
        </Link>
        <Link to="/Sessions">
          <div className="MenuBtn"><p className="MenuBtnTitle">meditáció</p></div>
        </Link>
        <Link to="/About">
          <div className="MenuBtn"><p className="MenuBtnTitle">rólunk</p></div>
        </Link>
      </div>
    )
  }
}

export default DesktopMenu
