import React, { useState, useEffect } from "react"

import NavBar from "../component/NavBar"
import ToHiGanCard from "./sessions/ToHiGanCard"

import "./Sessions.css"

const Sessions = () => {
  
  return(
    <div className="Page">
      <NavBar showLogo={true} />
      <div id="Sessions" className="Content Veiling">
        <div id="SessionCardsContainer">
          <ToHiGanCard />
        </div>
      </div>
    </div>
  )
}

export default Sessions
