import React, { useState } from "react"
import Link from "gatsby-link"

import "./PhoneMenu.css"

const PhoneMenu = () => {

  const [openPhoneMenu, setOpenPhoneMenu] = useState(false)

  const togglePhoneMenu = () => {
    setOpenPhoneMenu(!openPhoneMenu)
  }

  return(
    <div>
      <div id="PhoneMenuBtn" onClick={togglePhoneMenu}></div>
      <div id="PhoneMenuModal" className={openPhoneMenu ? "PhoneMenuModalOpen": "PhoneMenuModalClosed"}>
        <Link to="/About">
          <div className="PhoneMenuModalBtn"><p className="PhoneMenuModalBtnTitle">rólunk</p></div>
        </Link>        
        <Link to="/Sessions">
          <div className="PhoneMenuModalBtn"><p className="PhoneMenuModalBtnTitle">meditáció</p></div>
        </Link>
        <Link to="/Events">
          <div className="PhoneMenuModalBtn"><p className="PhoneMenuModalBtnTitle">események</p></div>
        </Link>
        <Link to="/Musing">
          <div className="PhoneMenuModalBtn"><p className="PhoneMenuModalBtnTitle">elmélkedések</p></div>
        </Link>
        <Link to="/Contact">
          <div className="PhoneMenuModalBtn"><p className="PhoneMenuModalBtnTitle">kapcsolat</p></div>
        </Link>
      </div>
    </div>
  )
}

export default PhoneMenu
