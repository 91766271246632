import React from "react"
import Link from "gatsby-link"

import DesktopMenu from "./DesktopMenu"
import PhoneMenu from "./PhoneMenu"

import "./NavBar.css"

const NavBar = (props) => {

  const showMenu = () => {
    let w = 800;
    if (typeof window !== "undefined") {
      w = window.innerWidth //because of npm build static fails due to window is undefined
    }

    if (w >= 800) {
      //Desktop or tablet
      return(<DesktopMenu />)

    } else {
      //Phone
        return(<PhoneMenu />)
    }
  }

  return(
    <nav id="NavBar">
      <Link to="/">
        <div id="Logo" className={props.showLogo ? "LogoShow" : "LogoHide"}>
          <p id="LogoText">Zen-Do</p>
        </div>
      </Link>
      {showMenu()}
    </nav>
  )
}

export default NavBar
